.AnswerOption {
  border-radius: 34.087px;
  border: 0.852px solid #959595;
  background: #FFF;
  padding: 8px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  // min-width: 380px;
  width: 100% !important;

  // &.smScreen {
  // width: 100% !important;
  // }
  &.effects {
    &:hover {
      border-radius: 34.087px;
      border: 0.852px solid #FF6700;
      background: #FFF9F4;
      cursor: pointer;

      & .ordernumber {
        background: #FF6700;
        color: #fff;
      }
    }
  }

  &.correctAnswer {
    border-radius: 34.087px;
    border: 0.852px solid green;
    background: #FFF9F4;

    & .ordernumber {
      background: green;
      color: #fff;
    }
  }

  &.wrongAnswer {
    border-radius: 34.087px;
    border: 0.852px solid red;
    background: #FFF9F4;

    & .ordernumber {
      background: red;
      color: #fff;
    }
  }

  &.active {
    border-radius: 34.087px;
    border: 0.852px solid #FF6700;
    background: #FFF9F4;

    & .ordernumber {
      background: #FF6700;
      color: #fff;
    }
  }

  &.correctAns {
    border-radius: 34.087px;
    border: 0.852px solid rgb(89, 165, 13);
    ;
    background: #fff;

    & .ordernumber {
      background: rgb(89, 165, 13);
      color: #fff;
    }
  }

  .ordernumber {
    display: inline-flex;
    background: #F4F4F4;
    color: #000;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    font-size: 17.043px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.452px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 13px;
  }

  .answer {
    color: #000;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.452px;
  }
}

.AnswerImage {
  display: flex;
  align-items: center;

  &.active {
    .answer {
      border: 3.866px solid #FF6700;
    }
  }

  &.correctAns {
    .answer {
      border: 3.866px solid rgb(89, 165, 13);
    }
  }
  &.wrongAnswer {
    .answer {
      border: 3.866px solid red;
    }
  }

  &.correctAnswer {
    .answer {
      border: 3.866px solid green;
    }
  }

  .ordernumber {
    margin-right: 36px;
  }

  &.effects {
    .answer {
      &:hover {
        border: 3.866px solid #FF6700;
        cursor: pointer;
      }
    }
  }

  .answer {
    border: 2.32px solid #39B9D6;
    display: inline-flex;

    & img {
      width: 180px;
      height: 126px;
      padding: 10px;
      object-fit: contain;
    }


  }
}

.SequenceAnswer {
  display: flex;

  flex-direction: column;
  justify-content: center;

  .SequenceAnswerList {
    align-items: center;
    margin-bottom: 20px;
    display: inline-flex;
    justify-content: center;

    & input {
      height: 50px;
      width: 50px;
      border-radius: 8px;
      border: 1px solid #C8C8C8;
      background: #FFF;
      margin-left: 36px;
      text-align: center;
    }
  }
}

.MatchAnswer {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .MatchAnswerList {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .AnswerOption {
      margin-right: 36px;
      min-height: 67px;
    }

    & input {
      height: 50px;
      width: 50px;
      border-radius: 8px;
      border: 1px solid #C8C8C8;
      background: #FFF;

      text-align: center;
    }
  }
}

.multiOptionQuestions {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .MultiOptionList {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .checkBoxOption {
      width: 38px;
      height: 38px;
      border-radius: 8px;
      border: 1px solid #C8C8C8;
      background: #FFF;
      display: inline-flex;
      margin-left: 7px;
      justify-content: center;
      align-items: center;

      &>img {
        display: none;
        width: 24px;
        height: 24px;
      }

      &.effects {
        cursor: pointer;

        &:hover {
          border: 1px solid #FF6700;

          &>img {
            display: flex;
          }
        }
      }


      &.active {
        &>img {
          display: flex;
        }

        border: 1px solid #FF6700;

      }
    }
  }
}

.mcqQuestions {
  display: flex;
  flex-wrap: wrap;

  .mcqAnswerList {
    width: 50%;
    margin-bottom: 22px;

    &:nth-child(odd) {
      display: flex;
      justify-content: flex-end;
      width: 50%;
      text-align: left;
      padding-right: 22px;
      box-sizing: border-box;
    }

    &:nth-child(even) {
      padding-left: 22px;
      box-sizing: border-box;
    }
  }
}

.TrueOrFalseOption {
  display: flex;
  justify-content: center;
}