.exam-notify-wrapper {
   
    .content {
        padding: 0 20px 20px;
        display: flex;
        justify-content: space-between;
        .contnetItem {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 0 4px;
        }
        .name{
            font-size: 14px;
            line-height: 27px;
            font-weight: 400;
        }
        .value{
            font-size: 15px;
            line-height: 36px;
            font-weight: 600;

        }
    }
   
    .footer {
        .footer-content {
            display: flex;
            justify-content: space-between;
            align-items: center; 
            & .button-wrap{
                justify-content: flex-end;
    display: flex;
    width: 100%;
    padding:0 20px;
            }
            .times {
                display: flex;
                flex: 2.5;
            }
            .info-wrapper {
                padding-right: 24px;
            }
        }
        .test-btn {
            border-radius: 8px;
            background: #FF6700;
            height: 42px;
            color: #FFF;
            text-align: center;
            font-family: 'Inter';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            border: 0;
            outline: none; 
            margin-top: 20px; 
            margin-bottom: 22px;
            width: 48%;
           
        }
           .test-btn[disabled]{
                opacity: 0.5;

           }

        .cancel-btn {
            border-radius: 8px;
            background-color: #fff;
            height: 42px;
            color: #FF6700;
            border: solid 1px #FF6700;
            text-align: center;
            font-family: 'Inter';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            margin-top: 20px; 
            margin-bottom: 22px;
            width: 48%;
        }
        .btnWrapper {
            display: flex;
            justify-content: flex-end
        }
        .button-wrap {
            flex: 1.6;
        }

        .timeValue {
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
        }
        .timeText {
            margin-left: 4px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
        }
    }
}

.ExamNotifyModal{
    & .modal-content{
        max-height: 600px;
        overflow-y: auto;
    }
}