.ScoreCardV3-wrapper {
    background-color: #fff;
    border: 1px solid #06aec9;
    border-bottom: 16px solid #06aec9;
    height: 680px;
    position: relative;
    border-radius: 40px;
    letter-spacing: 1px;

    &-thumbs-image {
        position: relative;
        width: 75px;
        top: 34px;

        &.up {
            width: 64px;
            top: 37px;
        }
    }

    &-header {
        background-image: url('../../assets/clientimages/Scorecard/header.svg');
        background-repeat: no-repeat;
        background-size: auto;
        height: 300px;
        width: 380px;
        text-align: center;
        letter-spacing: 1px;

        & span {
            color: #fff;
            font-size: 22px;
            position: relative;
            top: 52px;
            font-weight: 600;
            text-align: center;
        }

    }

    &-grade-sec {
        background-image: url('../../assets/clientimages/Scorecard/grade.svg');
        background-repeat: no-repeat;
        background-size: auto;
        width: 115px;
        height: 115px;
        position: absolute;
        top: 240px;
        left: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;

        & span {
            &:last-child {
                font-size: 26px;
                line-height: 18px;
            }
        }
    }

    &-percentage-sec {
        background-image: url('../../assets/clientimages/Scorecard/Diagnol.svg');
        background-repeat: no-repeat;
        background-size: auto;
        width: 200px;
        position: absolute;
        height: 200px;
        top: 122px;
        left: 86px;
        text-align: center;

        & div {
            font-size: 39px;
            color: #fff;
            position: relative;
            top: 51px;
        }
    }

    &-scoredata-wrapper {
        position: relative;
        top: 72px;
    }

    &-scoredata {
        background-image: url('../../assets/clientimages/Scorecard/scoredata.svg');
        background-repeat: no-repeat;
        background-size: auto;
        height: 50px;
        margin: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 23px 0 48px;
        color: #fff;
        text-transform: uppercase;

        & span {
            top: -2px;
            position: relative;
            font-weight: 600;
        }
    }

    // &-bottom-image{
    //     position: absolute;
    //     bottom: 0;
    // }
}