@import '../../globals/styles.scss';
@import '../../globals/fonts.scss';

.benefits-arrange {
    margin-top: 8%;
    margin-bottom: 5%;

    .BenefitsBanner-bg {
        background-color: $color-light-orange;
        border-radius: 16px;
        padding-top: 5%;
        padding-bottom: 5%;

        .container {
            color: $color-black; 
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 80%;

            img {
                margin-right: 12px;
                margin-top: 3px;
            }

            .row {
                grid-gap: 80px;

                .col {
                    .col {
                        display: flex;
                        align-items: start;
                        padding-bottom: 20px;
                    }
                }

            }
        }


        h3 {
            color: $color-orange;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 5%;
        }
    }
}
.paralist{
    color: #000; 
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}