@import '../../globals/styles.scss';
@import '../../globals/fonts.scss';
@import '../../globals/breakpoints.scss';

.tabParent {
    width: 100%;
    background: $color-bg-blue;
    padding: 5%;
    .tabsAndCard {
        width: 85%;
        margin: auto;
    }
}

.im-tabs.nav-tabs{
   border-bottom: none;
   margin-bottom: 1.5em;
    .nav-item {
        background: transparent;

        .nav-link {
            color: #848484;
            border: none;
            font-family: $font-poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            background: transparent;
            padding: 14px 10px;
            margin-right: 50px;
            &.active{ 
            color: #161616; 

                border-bottom: 2px solid $color-orange;
            }
        }

    }
    .view-all-link {
        flex: 1;
        text-align: right;
        a {
            margin: 0;
            color: $color-orange;
            img {
                margin-left: 10px;
            }
        }
    }
}