.profileContainer {
  width: 100%;

  & .backbtn {
    max-width: 60px;
    cursor: pointer;
  }

  .container {
    padding-top: 14px;
  }

  .profileText-title {
    color: #010101;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 24px;

  }

  .btn-wrapper {
    width: 100%;
  }

  .profileInformation {
    padding-top: 24px;
    margin-bottom: 30px;

    .formWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .col-6 {
        margin-top: 0;
        width: 48%;
      }
    }

    .profile-label {
      color: #646464;
      font-family: 'Inter';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      display: block;
    }

    .profile-input.form-control {
      border-radius: 8px;
      border: 0.899px solid #96A4C2;
      background: #FFF;
      height: 42px;
      color: #000;
      font-family: 'Inter';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;

      &:focus {
        box-shadow: none;
      }
    }

    .signup-select.form-select {
      border-radius: 8px;
      border: 0.899px solid #96A4C2;
      height: 42px;
      color: #000;
      font-family: 'Inter';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }

    .signup-wrapper {
      padding: 0 30px;
    }

    .signup-input-box {
      &>input[disabled] {
        background: #EDEDED;
        border: none;
      }

      &>select[disabled] {
        background: #EDEDED;
        border: none;
      }
    }

    .profile-input-box {
      margin-bottom: 24px;

      &>input[disabled] {
        background: #EDEDED;
        border: none;
      }

      &>select[disabled] {
        background: #EDEDED;
        border: none;
      }

    }

    .profile-input-error {
      color: red;
      font-family: 'Inter';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      position: relative;
      top: 0
    }

    .profile-input-gloabl-error {
      color: red;
      font-family: 'Inter';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      position: relative;
      top: -13px
    }

    .signup-btn {
      border-radius: 8px;
      background: #FF6700;
      height: 42px;
      color: #FFF;
      text-align: center;
      font-family: 'Inter';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      border: 0;
      outline: none;
      margin-top: 20px;
      margin-bottom: 22px;
      width: 48%;
    }

    .cancel-btn {
      border-radius: 8px;
      background-color: #fff;
      height: 42px;
      color: #FF6700;
      border: solid 1px #FF6700;
      text-align: center;
      font-family: 'Inter';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin-top: 20px;
      margin-bottom: 22px;
      width: 48%;
    }

    .btn-wrapper {
      display: flex;
    }

    .eye-btn {
      padding: 8px;
      border: 0.899px solid #96A4C2
    }
  }

  .subjectFilterBox {
    width: 300px;
  }

  .subjectFilterBox-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-options {
      display: flex;
      align-items: end;

      &>span {
        margin-left: 16px;
        color: #2C93CD;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    & .subjectTitle{
        &>span{
          font-weight: 600;
        }
    }

  }

  .cardWrapper {
    margin-top: 30px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 14px 0px #00000026;

    .headerWrapper {
      display: flex;
      justify-content: space-between;
      padding: 15px 15px;

      .title {
        font-size: 22px;
        font-weight: 500;
        line-height: 36px;
      }

      .times {
        &>span {
          font-size: 20px;
          line-height: 30px;
          font-weight: 600;
          padding: 10px;
        }

        .suffix {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    .contentWrapper {
      display: flex;
      justify-content: space-between;
      padding: 15px 15px;

      .testItem {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      .testItemtext {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
      }

      .testItemValue {
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
      }

      .test-btn {
        border-radius: 8px;
        background: #FF6700;
        height: 42px;
        color: #FFF;
        text-align: center;
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        border: 0;
        outline: none;
        margin-top: 20px;
        margin-bottom: 22px;
        width: 100%;
      }

      .test-btn[disabled] {
        opacity: 0.5;

      }

      .cancel-btn {
        border-radius: 8px;
        background-color: #fff;
        height: 42px;
        color: #FF6700;
        border: solid 1px #FF6700;
        text-align: center;
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-top: 20px;
        margin-bottom: 22px;
        width: 48%;
      }

      .btnWrapper {
        display: flex;
        flex: 1;
        justify-content: space-around;
      }
    }
  }


}

.nodataFound {
  margin-top: 30px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 14px 0px #00000026;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
}

.SubjectFilterTypeHead {
  & input {
    height: 40px;
    font-size: 13px;
  }

  & .rbt-menu {
    &>a {
      padding: 4px 10px;
      font-size: 13px;
    }
  }
}
.selectedOptionWrapper{
  display: flex;
  gap: 4px;
  flex-direction: column;
}