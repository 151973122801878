.signup-label{
    color: #646464;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: block;
}
.signup-input.form-control{
    border-radius: 8px;
    border: 0.899px solid #96A4C2;
    background: #FFF;
    height: 42px;   
    color: #000;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
    &:focus {
        box-shadow: none; 
    }
}
.signup-select.form-select{
    border-radius: 8px;
    border: 0.899px solid #96A4C2;
    height: 42px;  
    color: #000;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
}
.signup-wrapper{
    padding: 0 30px;
}
.signup-input-box{
    margin-bottom: 12px;
}
.signup-input-error{
    color: red;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    position: relative; 
    top:0
}
.signup-input-gloabl-error{
    color: red;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    position: relative; 
    top:-13px
}
.signup-btn {
    border-radius: 8px;
    background: #FF6700;
    height: 42px;
    color: #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    border: 0;
    outline: none; 
    margin-top: 20px; 
    margin-bottom: 22px;
    width: 100%;
}
.CollegeTypeHead{
    & input{
        border-radius: 8px;
        border: 0.899px solid #96A4C2;
        background: #FFF;
        height: 42px;   
        color: #000;
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; 
        &:focus {
            box-shadow: none; 
        }
    }
    #basic-typeahead-single{
        &> a{
            font-size: 13px;
        }
    }
}
.formWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .col-6 {
       width:48%;
    }
}