.grade-wrapper-box {
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.12);
    min-width: 350px;
    max-width: 350px;

    &>img {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-top-right-radius: 14px;
        border-top-left-radius: 14px;
    }
    &-details {
      padding: 26px 20px 36px;
      & h3 {
        color: #222;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .grade-header{
        font-weight: 600;
        margin-top: 20px;
      }
      &>ul{
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 13px;
        margin-bottom: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #e4e4e4;
        &>li{
            &.grade{
                flex: 2.8;
            }
            &.points{
                flex: 1;
            }
            &.percent{
                flex: 1.6;
                text-align: right;
            }
            
        }
      }
     
    }
  }