.TestProcess {
    background: url('../../assets/QUIZ.png') no-repeat;
    background-size: cover;
    padding: 100px 0;

    h3 {
        color: #000;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 20px;
    }

    p {
        color: #222;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 133.333% */
        margin-bottom: 10px;

    }

    ul {
        margin-left: 16px;
        margin-top: 16px;
        margin-bottom: 16px;

        li {
            color: #606060;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 10px;
        }
    }

    .onlineTest-point{
        padding-bottom: 20px;
        &:first-of-type{
            padding-top: 20px;

        }
        &>h5{
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 8px;
        }
        &>p{
            font-size: 14px;
            font-weight: 400;
            color: #606060;
        }
    }
}
 