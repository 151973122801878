.BreadcrumbBox{
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    &>li{
        color: #FBFBFB; 
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
        &>a{
            color: #FBFBFB; 
            text-decoration: underline !important;
            cursor: pointer;
        }
        &>img{
            padding: 0 8px;
        }
    }
}