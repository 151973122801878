@import '../../globals/styles.scss';
@import '../../globals/fonts.scss';
@import '../../globals/breakpoints.scss';

.im-footer {
    display: flex;
    border: 2px solid #F9F4F0;
    background-color: #f2f2f2;
    .im-footer-container{
        @media screen and (min-width:1000px) and (max-width:1200px) {
            min-width: 1000px;
            max-width: 1000px;
        }
    }
    .footerSec {
        display: flex;
        padding-top: 40px;
        padding-bottom: 40px; 
    }
    h3 {
        img {
            margin-right: 10px;
        }
        color: $color-black;
        font-family: $font-poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 10px;
    }
    p {
        color: $color-mid-grey;
        font-family: $font-poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
    }
    a{
        color: $color-mid-grey;
        font-family: $font-poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        display: block;
        margin-bottom: 0;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
    &-secondary {
        display: flex;
        border-top: 1px solid #BCBCBC;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        background-color: #f2f2f2;
        padding-bottom: 20px;
        .copyright-text {
            margin-bottom: 0;
            color: $color-black;
            font-family: $font-poppins;
            font-size: 14px;
        }
        .im-navbar-secondary {
            background-color: transparent !important;
            .im-menubar-secondary {
                color: $color-black;
                font-family: $font-poppins;
                font-size: 14px;
                gap: 40px;
            }
        }
        .social-icons {
            display: flex;
            gap: 24px;
            justify-content: flex-end;
            & img{
                width: 38px;
            }
        }
    }
    .im-footer-secondary {
        background-color: #d5d5d5;
    } 
}
@include xs {
    .im-footer-secondary {
        text-align: center;
    }
    .im-footer-secondary .social-icons {
        justify-content: center;
    }
}
@include sm {
    .im-footer-secondary {
        text-align: center;
    }
    .im-footer-secondary .social-icons {
        justify-content: center;
    }
}