@import '../../globals/styles.scss';
@import '../../globals/fonts.scss';
@import '../../globals/breakpoints.scss';

.card-wrapper {
  &.card {
    border-radius: 8.93px;
    border: 2px solid #F5F5F5;
    background: #FFF;
    padding: 2px;
    box-shadow: 0px 0px 15.18051px 0px rgba(0, 0, 0, 0.05);
  }
  & .cardDescription{
    font-style: italic;
    color: #FF6700;
    text-decoration: underline;
    cursor: pointer;
  }
  & .card-img-top{
    height: 168px;
    object-fit: fill;
  }
  & .subjectdetails-box-suboptions {
    list-style: none;
    margin: 30px 0 38px;
    padding: 0;
    margin-bottom: 24px;

    & li {
      color: #353535;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      & img {
        margin-right: 6px;
        width: 20px;

      }
    }
  }

  & .subject-list-card {
    &>.card-title {
      color: #010101;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    & .list-group {
      margin-top: 13px;
      margin-bottom: 13px;

      & .list-group-item {
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: 8px;
        border: none;
        color: #222;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &>img {
          width: 24px;
          height: 24px;
          margin-right: 10px;

        }
      }
    }

  }

  img {
    width: 100%;
  }

  .card-body {
    padding: 20px;

    .card-title {
      color: #222;
      font-family: $font-poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    p {
      color: $color-black;
      font-family: $font-poppins;
      text-align: left;
    }

    .card-text {
      color: #000;
      font-family: $font-poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: justify;
      min-height: 90px;
    }

    .price {
      display: flex;
      justify-content: space-between;
      margin-top: 18px;
      margin-bottom: 20px;
      align-items: center;

      & .finel-price {
        color: #0D0D0D;
        font-family: $font-poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      & .strike-price {
        color: #757575;
        font-family: $font-poppins;
        font-size: 15px;
        font-style: italic;
        font-weight: 500;
        line-height: normal;
        text-decoration: line-through;
        margin-left: 12px;
      }

      .duration {
        color: #141414;
        font-family: $font-poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        & > span{
          font-size: 13px;
          font-weight: 400;
        }
      }
    }

    .register-button {
      background-color: $color-orange;
      border-radius: 7px;
      width: 100%;
      color: #FFF;
      height: 46px;
      text-align: center;
      font-family: $font-poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border: none;
    }
  }
  .mt-20{
    margin-top: 12px;
  }
}