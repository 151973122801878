@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Squada+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "react-toastify/dist/ReactToastify.css";
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  height: 100vh;
  font-size: 10px;
}
.customMaxWidth.container{
  max-width: 1120px;
}
body {
  margin: 0;
  font-family:'Poppins', sans-serif;
  font-size: 1.6em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  height: 100%;
  width: 100%;
}
#root{
  height: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border:1px solid #383838;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}



.header-submenu{
  display: flex;
  background: #FF6700;
  // padding: 10px;
  &>.container{
    justify-content: center;
    display: flex;
    &>a{
      color: #FFF !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 12px;
      text-decoration: none;
      cursor: pointer;
    }
  }
}