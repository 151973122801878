.ProfileHeader {
    background: url('../../assets/blue-linear-bg.png') no-repeat;
    background-size: cover;
    min-height: 280px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    & h3 {
        color: #FBFBFB;
        font-size: 38px;
        font-style: normal;
        font-weight: 600;
        line-height: 78px;
        margin-bottom: 10px;
    }

    & p {
        color: #FFF;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .role {

        font-family: 'Inter';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #FFF;
        margin-top: 4px;

    }

    .name {
        color: #FFF;

        font-family: 'Inter';
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }

    .nameWrapper {
        padding:12px 0px 11px 0px;
        display: flex;
        align-items: center;

    }
    .IHSID{
        font-size: 16px;
        color: #fff;
    }

    .backBtnWrapper {
        align-items: center;
        display: flex;
        padding: 32px 0 0;
    }

    .profileText {
        padding-left: 24px;
        color: #FBFBFB;
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: 78px;
        font-family: 'Inter';
    }

    .profilePhoto {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: #FF6700;
        font-size: 35px;
        color: #fff;
        text-align: center;
    }

    .nameTextWrapper {
        padding-left: 6px;
        display: flex;
        flex-direction: column;
    }

}