.OnlineTest {
    background: url('../../assets/QUIZ.png') no-repeat;
    background-size: cover;
    padding: 65px 0;

    h3 {
        color: #000;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 20px;
    }

    p {
        color: #222;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 133.333% */
        margin-bottom: 10px;

    }

    ul {
        margin-left: 16px; 
        margin-bottom: 20px;

        li {
            color: #606060;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 10px;
        }
    }
}

.next-button {
    &.btn {
        background-color: #FF6700;
        border-radius: 7px;
        width: 200px;
        color: #FFF;
        height: 46px;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: none;
        transition: all 0.3s ease-in;
        position: relative;
        &:hover {
            border: 1px solid #FF6700;
            color: #FF6700;
            background: #fff;
            & img{
            color: #FF6700;

            }
        }
        & img{
            width: 18px;
            position: absolute;
            right: 14px;
            top: 16px;
        }
    }

}