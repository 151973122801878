@import '../../globals/styles.scss';
@import '../../globals/fonts.scss';
@import '../../globals/breakpoints.scss';

.im-banner {
    position: relative;
    background-image: url('../../assets/home-banner.png');
    height: 520px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2em;
    .im-banner-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: inherit;
    }
    .title {
        color: #FBFBFB;
        max-width: 741px;
        font-family: $font-poppins;
        font-size: 2.3vw ;
        font-style: normal;
        font-weight: 600;
        line-height: 4vw;
        word-wrap: break-word;
        word-break: break-word;
        padding-right: 70px;
    }
 
    .highlight {
        background-color: $color-orange;
    }
  
    .im-banner-box-image{
        display: flex;  
        min-width: 360px;
        max-width: 416px;
        height: inherit;
        align-items: flex-end;
        & img{
            width: 100%;
        }
    }
}
@include xs{
    .im-banner .title {
        max-width: 60%;
        padding-right: 10px;
    }
    .im-banner .im-banner-box-image {
        min-width: 40%;
        max-width: 40%;
        align-items: center;
    }
    
    .im-banner {
        height: 400px;
    }
}
@include sm{
    .im-banner .title {
        max-width: 60%;
        padding-right: 10px;
    }
    .im-banner .im-banner-box-image {
        min-width: 40%;
        max-width: 40%;
        align-items: center;
    }
    .im-banner {
        height: 400px;
    }
}
@include md {
    .im-banner .title {
        max-width: 60%;
        padding-right: 10px;
    }
    .im-banner .im-banner-box-image {
        min-width: 40%;
        max-width: 40%;
        align-items: center;
    }
    
}