@import '../../globals/styles.scss';
@import '../../globals/fonts.scss';
@import '../../globals/breakpoints.scss';

.welcome-section {
  padding: 100px 0;

  .courseDesc {
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;

    .descleft {
      padding: 0px 20px 10px 10px;
      width: 55%;

      p {
        font-size: 13px;
        text-align: justify;
      }
    }

    .descRight {
      width: 40%;

      img {
        width: 100%;
      }
    }
  }

  .container {

    .title {
      color: $color-black;
      font-family: $font-poppins;
      font-size: 2.2vw;
      font-weight: 600;
      padding-right: 10px;
      line-height: 46px;
      margin-bottom: 16px;

      .highlight {
        color: $color-orange;
      }
    }
  }
}

.highlight-section {
  background-image: url('../../assets/Highlights_Background-05.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  padding: 100px 0px;

  .courseDesc {

    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-right: 60px;

    .descleft {
      padding-right: 20px;
      display: flex;
      align-items: center;

      img { 
        max-width: 100%;
        position: relative;
        left: 0px; 
      }
    }

    .descRight {
      width: 65%;
      padding-left: 48px;
      box-sizing: border-box;
      &>img{
        width: 100%;
      }
      &>p {
        color: #000;
        text-align: right;
        font-family: $font-poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 30px; 
      }
    }
  }

  .container {
    .title {
      color: $color-black;
      font-family: $font-poppins;
      font-size: 2.2vw;
      font-weight: 600;
      line-height: 71px;
      margin-bottom: 50px;
      text-align: right; 
      .highlight {
        color: $color-orange;
      }

      @include xl {
        font-size: 40px;
        line-height: 61px;
      }
    }

    .feature {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: flex-end;
      gap: 20px;
      flex-wrap: wrap;

      .feature-cards {
        border-radius: 40px;
        background: #FFF;
        box-shadow: 0px 1px 19px 0px rgba(0, 0, 0, 0.09);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        padding: 20px;
        border: none;
        width: 220px;
        @include xl {
          width: 300px;
        }
        @include lg {
          width: 250px;
        }
        img {
          width: 100%;
          max-width: 150px;
          height: 100%;
          max-height: 150px;
          padding: 10px;
        }

      }

      .card-1 {
        color: #30994E;
      }

      .card-2 {
        color: #E41210;
      }

      .card-3 {
        color: #009E95;
      }

      .card-4 {
        color: #5653EE;
      }
    }
  }
}
.welcomePara{
  font-size: 15px !important;
}
@include xs {
    .welcome-section {
      padding: 50px 0;
    }
    .welcome-section .courseDesc {
      display: block;
    }
    .welcome-section .courseDesc .descleft {
      padding: 0px 20px 20px 20px;
      width: 100%;
    }
    .welcome-section .courseDesc .descRight {
      width: 100%;
      padding: 0 20px;
    }
    .highlight-section {
      padding: 50px 0;
    }
    .highlight-section .container .title {
      text-align: start;
      padding: 0 20px;
    }
    .highlight-section .courseDesc {
      display: block;
    }
    .highlight-section .courseDesc .descleft {
      display: block;
      padding-left: 48px;
    }
    .highlight-section .courseDesc .descRight {
      width: 100%;
      padding-top: 20px;
    }
}
@include sm {
  .welcome-section {
    padding: 50px 0;
  }
  .welcome-section .courseDesc {
    display: block;
  }
  .welcome-section .courseDesc .descleft {
    padding: 0px 20px 20px 20px;
    width: 100%;
  }
  .welcome-section .courseDesc .descRight {
    width: 100%;
    padding: 0 20px;
  }
  .highlight-section {
    padding: 50px 0;
  }
  .highlight-section .container .title {
    text-align: start;
    padding: 0 20px;
  }
  .highlight-section .courseDesc {
    display: block;
  }
  .highlight-section .courseDesc .descleft {
    display: block;
    padding-left: 48px;
  }
  .highlight-section .courseDesc .descRight {
    width: 100%;
    padding-top: 20px;
  }
}

// .colorWrapper{
//   border: 1px solid;
//   width: 100px;
//   display: block;
//   height: 100px;
// }
// .colordata{
//   width: 25px;
//   display: inline-block;
// }