.NurseAboutUs-section-one {
    padding-top: 90px;
    padding-bottom: 90px;

    & .left-section {
        &>h3 {
            color: #FF6700;
            font-size: 34px;
            font-style: normal;
            font-weight: 600;
            line-height: 78px;
            /* 177.273% */
        }

        &>p {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: justify;
        }
    }

    & img {
        padding-left: 100px;

        width: 95%
    }


}

.NurseAboutUs-section-two {

    & img {
        padding-right: 100px;

        width: 95%
    }

    & .right-section {
        &>p {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: justify;
        }

        &>ul {
            list-style: none;
            margin: 0;
            padding: 0;

            &>li {
                color: #FF6700;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: justify;
                margin-bottom: 10px;
            }
        }
    }

}

.NurseAboutUs-section-three {
    padding-top: 90px;
    padding-bottom: 90px;

    & img {
        width: 100%
    }

    & .right-section {
        &>h3 {
            color: #FF6700;
            font-size: 34px;
            font-style: normal;
            font-weight: 600;
            line-height: 78px;
            /* 177.273% */
        }

        &>ul {
            list-style: none;
            margin: 0;
            padding: 0;

            &>li {
                color: #222;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: justify;
                margin-bottom: 10px;
            }
        }
    }

}

.NurseAboutUs-bg {
    background-image: url('../../assets/Online_Test_Nurses_Background.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    padding: 100px 0px;

}