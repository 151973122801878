.otp-input-style {
    & span{
       padding: 0px 5px;
    }
    & input{
        height: 44px;
        font-size: 18px;
    }
}
.otp-error {
    padding-top: 24px;
}