.CartDrawer-wrapper {
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: none;
  position: absolute;
  z-index: 999;
  width: 100%;
  transition: transform 0.3s ease-out;

  &.wrapper-open {
    display: flex;
  }
}

.CartDrawer {
  position: fixed;
  top: 0;
  right: 500px;
  width: 500px;
  height: 100vh;
  z-index: 9999;
  visibility: hidden;
  background: #fff;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.cartHeaderContentWrapper {
  height: calc(100vh - 166px);
  overflow-y: auto;
}

.CartDrawer.open {
  transform: translateX(0);
  right: 0;
  visibility: visible;
}

.cartHeader {
  display: flex;
  justify-content: space-between;
  padding: 18px 30px;
  border-bottom: 1px solid #e5dede;
  margin-bottom: 20px;

  &>.close {
    cursor: pointer;
    font-weight: 600;
  }
}

.cartItems {
  padding: 10px 30px 20px;
  margin-bottom: 20px;
  display: flex;
  border-bottom: 1px solid #e5dede;

  & .cartItemImg {
    width: 150px;

    img {
      width: 150px;
    }
  }

  & .cartTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    & h4 {
      margin-bottom: 0;
    }

    & svg {
      width: 15px;
      cursor: pointer;
      fill: #FF6700;
    }
  }

  & .cartItemContent {
    padding-left: 20px;

    h4 {
      color: #222;
      font-family: "Poppins", sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }


    p {
      color: #000;
      font-family: "Poppins", sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: justify;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      /* start showing ellipsis when 3rd line is reached */
      white-space: pre-wrap;
    }

    .price-list {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      font-size: 15px;
    }
  }
}

.emptyCart{
  display: flex;
  justify-content: center;
  color: gray;
  font-size: 18px;
  margin-top: 40px;
}
.cartFooter-wrapper {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  width: 100%;
}

.discount-strip {
  background: rgb(47, 124, 96);
  font-size: 18px;
  color: #fff;
  padding: 10px 30px;
}

.cartFooter {
  padding: 20px 30px;
  border-top: 1px solid #e5dede;


  & .price-details {
    display: flex;
    justify-content: flex-end;

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      font-size: 15px;

      & li {
        display: flex;
        justify-content: space-between;

        span {
          font-weight: 600;

          &:first-child {
            margin-right: 60px;
          }
        }
      }
    }
  }

  & .cart-btn {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #e5dede;
    padding-top: 10px;
  }

  & button {
    padding: 10px 20px;
    background: #000;
    color: #fff;
    border: none;
    background-color: #FF6700;
    cursor: pointer;
    border-radius: 7px;
  }

  & .checkoutBtn{
    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
}