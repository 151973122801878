.AboutHeader {
    background: url('../../assets/blue-linear-bg.png') no-repeat;
    background-size: cover;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & h3 {
        color: #FBFBFB;
        font-size: 38px;
        font-style: normal;
        font-weight: 600;
        line-height: 78px;
        margin-bottom: 10px;
    }

    & p {
        color: #FFF;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-style: italic;
    }
}