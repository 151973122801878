.quizWrapper {
    padding-top: 26px;
    padding-bottom: 49px;
    background: url('../../assets/QUIZ.png') no-repeat;
    height: calc(100vh - 65px);
    background-size: cover;
}

.quizWrapperContainer {
    max-width: 1500px;
    max-height: 100%;
}

.quizHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .noOfQuestion { 
        height: 46px;
        margin-left: 0px;
        display: flex;
        justify-content: center; 
        align-items: center;
        color: #fff !important;
        font-family: 'Poppins';
        font-size: 16px;
        font-style: normal;
        padding-top: 6px;
        font-weight: 500;
        line-height: 24px;
        background: url('../../assets/quiz/Noofquestion.png') no-repeat;
        background-size: cover;
        width: 300px;
    }

    .timeleft-disclimer {
        color: #020202;
        font-family: 'Poppins';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .timeleft-wrapper {
        display: flex;
        align-items: center;

        p {
            margin: 0;
        }
    }

    .timeleft {
        border-radius: 12px;
        border: 1px solid #FF6700;
        background: #FFF;
        width: 120px;
        height: 46px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            color: #FF6700;
            text-align: center;
            margin-bottom: 0;
            font-family: 'Inter';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

    }
}

.quizAnswerSection {
    border-radius: 8px;
    padding: 70px 40px;
    background: #FFF;
    box-shadow: 0px -1px 15px 0px rgba(0, 0, 0, 0.11);
    margin-top: 26px;
    height: calc(100vh - 280px);
    overflow-y: auto;
    border: 4px solid #FF6700;
}
.quizReviewListSection.quizAnswerSection{
    height:auto;
    padding: 25px 40px;
}
.quizNext {
    border-radius: 4px;
    background: #FF6700;
    width: 184px;
    height: 55px;
    text-transform: uppercase;
    color: #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 0;
    position: relative;

    &>img {
        position: absolute;
        right: 18px;
    }

    &:disabled {
        background: #FF6700;
        color: #FFF;
    }
}

.quizReview {
    border-radius: 4px;
    width: 184px;
    height: 55px;
    text-transform: uppercase;
    color: #000;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 1px solid #FF6700;
    position: relative;
    margin-right: 20px;

    &:disabled {
        color: #000;
        border: 1px solid #FF6700;
    }
}

.btn-wrapper {
    display: flex;
    justify-content: space-between !important;

    &.noskip {
        justify-content: flex-end !important;

    }

    & .quizNext.btn,
    & .quizReview.btn {
        background-color: #FF6700;
        border-radius: 7px;
        width: 100%;
        color: #FFF;
        height: 46px;
        text-align: center;
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: none;
        transition: all 0.3s ease-in;

        &:hover {
            border: 1px solid #FF6700;
            color: #FF6700;
            background: #fff;
        }

        &.submitbtn {
            background-color: #007a35;

            &:hover {
                border: 1px solid #007a35;
                color: #007a35;
                background: #fff;
            }

        }
    }

    .quizNext {
        max-width: 200px;
    }

    .quizReview {
        max-width: 200px;
        margin-right: 20px;
    }
}

.quizQuestionImage {
    margin-top: 10px;
    width: 400px;
}

.quizQuestion {
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.quizQuestionType {
    color: #AEAEAE;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    margin-top: 32px;
    margin-bottom: 50px;
    text-align: center;
}

.quizQuestionHeader {
    text-align: center;
}
.quizSkipArrow{
    width: 200px; 
    height: 46px;
    cursor: pointer;
}
.quizNextArrow{
    width: 200px; 
    height: 46px;
    cursor: pointer;
    &.disabledNext{
        opacity: 0.5;
        pointer-events: none;
    }
}
.quizSkip {  
    width: 200px; 
    height: 46px;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    border: 1px solid #FF6700;
    font-weight: 600;
    line-height: normal;
    transition: all 0.3s ease-in;
    text-decoration: none;
    cursor: pointer;
    background: url('../../assets/quiz/skip.png') no-repeat;
    background-size: cover;
}

.quizReviewList {
    height: calc(100vh - 72px);
    overflow-y: scroll;
}

.dividerSpan {
    margin: 10px 50px;
    height: 90px;
    border-right: 1px solid #959595;
}

.imagequestionWidth {
    flex: 1;
}

.imagequestionWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    &>.quizQuestionImageBox {
        width: 40%;
        padding-right: 50px;

        &>.quizQuestionImage {
            width: 100%;
        }
    }

    &>.imagequestionWidth {

        &>.mcqQuestions {
            & .mcqAnswerList {
                width: 100% !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
}