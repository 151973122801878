.scorePage-wrapper {
    background: url('../../assets/QUIZ.png') no-repeat;
    background-size: cover;
    height: calc(100vh - 73px);

    & .dummy {
        height: 30px;
    }

    & .score-container {
        display: flex;
        height: 95%;
        gap: 80px;
        max-width: 1300px;
    }

    & .scorecard-wrapper {
        min-width: 350px;
        display: flex;
        align-items: center;
        position: relative;

    }

    & .finalScore-wrapper {
        height: 95%;
        overflow-y: auto;
        padding: 0 30px 30px;

        & .quizAnswerSection {
            height: auto;

            & .quizQuestion {
                font-size: 18px;
            }

            & .quizQuestionType {
                font-size: 16px;
                margin-top: 24px;
                margin-bottom: 30px;

            }

            & .AnswerOption {
                padding: 6px;

                & .ordernumber {
                    width: 40px;
                    min-width: 40px;
                    height: 40px;
                }

                & .answer {
                    font-size: 13px;
                }
            }

        }
    }
}

.scorecard-goback-btn {
    position: absolute;
    top: 30px;
    border-radius: 34.087px;
    border: 0.852px solid #FF6700;
    left: 75px;
    background: #fff;
    color: #FF6700;
    padding: 10px 26px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        border: 0.852px solid #FF6700;
        color: #fff;
        background: #FF6700;
    }
}