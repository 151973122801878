.NSAboutUs-section-one {
    padding-top: 90px;
    padding-bottom: 90px;

    & .left-section {
        &>h3 {
            color: #FF6700;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 20px;
        }

        &>p {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: justify;
        }
    }

    & img {
        padding-left: 100px;

        width: 95%
    }


}

.NSAboutUs-section-two {
    margin-bottom: 80px;

    & img {
        width: 100%
    }

    & .NSAboutUs-leftImage {
        width: 70%;
    }

    & .right-section {
        &>h3 {
            color: #FF6700;
            font-size: 34px;
            font-style: normal;
            font-weight: 600;
            line-height: 78px;
            /* 177.273% */
        }

        &>ul {
            list-style: none;
            margin: 0;
            padding: 0;
            padding-left: 20px;

            &>li {
                color: #222;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: justify;
                margin-bottom: 10px;
            }
        }
    }

}

.NSAboutUs-bg {
    background-image: url('../../assets/Online_Test_Students_Background.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
  padding: 100px 0px;

}