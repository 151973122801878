@import '../../globals/styles.scss';
@import '../../globals/fonts.scss';
@import '../../globals/breakpoints.scss';
.regnow-button{
    &.btn{
        background-color: $color-orange;
        border-radius: 7px;
        width: 100%;
        color: #FFF;
        height: 46px;
        text-align: center;
        font-family: $font-poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: none;
        transition: all 0.3s ease-in;

        &:hover{
            border:1px solid $color-orange;
            color: $color-orange;
            background: #fff;
        }
    }
   
}