@import '../../globals/styles.scss';
@import '../../globals/fonts.scss';
@import '../../globals//breakpoints.scss';

.brand-logo {
  width: 200px;

  @include xl {
    width: 160px;
  }

  @include lg {
    width: 120px;
  }
}

.header-wrapper-bg.bg-body-tertiary.im-navbar {
  background-color: #FFF !important;
}

.CartBtn {
  border: 1px solid #FF6700;
  display: flex;
  padding: 0px 12px;
  border-radius: 7px;
  margin-left: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in;

  &.headerCart {
    border: none;
    margin-right: 12px;
    min-height: 40px;
  }

  & svg {
    width: 20px;
    fill: #FF6700;
  }

  &:hover {
    background-color: #FF6700;

    & svg {
      fill: #fff;
    }
  }
}

.login-popup {
  &-wrapper {
    & .modal-content {
      border: 0;
      border-radius: 6px;

      & .login-popup.modal-body {
        padding: 0;
        display: flex;
        align-items: top;
      }
    }
  }
}

.exam-notify-popup {
  padding: 0 !important;
  &>.title {
    padding: 24px;
    font-size: 26px;
    text-align: center;
    font-weight: 600;
  }
}

.im-navbar {
  &.navbar {
    padding: 0;
  }

  .container {
    a {
      margin: 0;
      padding: 0;

      .brand-logo {
        margin: 11px 0;
      }
    }

    .im-menubar {
      .active-link {
        color: $color-orange !important;
        padding-bottom: 5px !important;
      }

      .menu-link {
        color: $color-mid-grey;
        margin-right: 14px;
        font-family: $font-poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 47px;
        padding: 13px 12px;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: $color-orange;
          padding-bottom: 5px;
        }

        & .submenu-link {
          display: flex;
          width: 100%;
          border-radius: 2px;
          background: #EB7728;
          backdrop-filter: blur(2px);
          position: absolute;
          left: 0;
          bottom: -45px;
          z-index: 9999;

          & a {
            color: #FFF;
            font-family: $font-poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration: none;
            margin-right: 32px;

            &:hover {
              padding-bottom: 5px;
              border-bottom: 2px solid #fff;
            }
          }
        }
      }
    }
  }
}

.nav-link {
  &::after {
    color: $color-mid-grey;
    vertical-align: 0.2rem;
  }

  .user-name {
    color: $color-mid-grey;
    text-align: right;
    font-family: $font-poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.6px;
    margin-right: 6px;

    img {
      width: 42px;
      height: 42px;
      margin-left: 15px;
    }

    @include xl {
      font-size: 16px;

      img {
        width: 36px;
        height: 36px;
      }
    }
  }
}

.submenu {
  position: absolute !important;
  top: 10%;
  z-index: 999;
  width: 100%;

  .container {
    .im-menubar {
      margin: 0;
    }
  }
}

#basic-navbar-nav {
  justify-content: center;

  .im-menubar.navbar-nav {
    justify-content: center;

  }
}

.profile {
  .circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #3498db;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
  }
}

.login-bg {
  max-width: 560px;
}

.signup-bg {
  max-width: 500px;
  object-fit: cover;
  height: 100%;
}

.signup-popup-wrapper {
  & .modal-content {
    height: 690px;
  }
}

.loginOption {
  color: #FF6700;
  font-weight: 600;
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-top: 15px;
  cursor: pointer;
}

.logout-btn.dropdown-item {
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  padding: 8px 12px !important;

  &:hover {
    background-color: #3498db;
    color: #fff;
  }
}

.profile-wrapper {
  & .dropdown-menu {
    width: 120px;

  }

}

.profilePhoto {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #FF6700;
  font-size: 16px;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-right: 6px;
}

.login_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 24%;
  padding-top: 6px;

  .btn {
    margin: 0 5px;
    padding: 0 10px;
    width: 45%;
    line-height: 30px;
  }

  span {
    border-left: 2px solid $color-grey;
    height: 25px;
    margin: 0 5px;
  }
}

.popup_close {
  position: absolute;
  right: 15px;
  border: 1px solid;
  top: 15px;
  padding: 3px 8px;
  border-radius: 20px;
  color: $color-grey;
  font-weight: bold;
  cursor: pointer;
  z-index: 2;
  text-decoration: none;
  height: 30px;

  &:hover {
    color: $color-white;
    background: $color-orange;
  }
}

.login_tabs {
  width: 50%;
  display: flex;
  align-items: center;

  .im-tabs {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    padding-top: 25px;

    .nav-item {
      width: 50%;
      text-align: center;

      button {
        width: 100%;
      }
    }
  }
}


.quiz-header {
  display: flex;
  padding: 10px 0px;
  & .quiz-header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    & span{
      color: #FF6700;
    }
    & .brand-logo {
      width: 140px;
    }
  }
 
}

.backArrow-logo {
  margin-right: 20px;
  cursor: pointer;
}

.profile-section {
  flex-grow: unset !important;
}