@import '~bootstrap/scss/bootstrap';
@import './globals/styles.scss';
@import './globals/fonts.scss';

body {
  font-family:$font-poppins;
  font-size: 1.6em;
}
.App {
  text-align: center;
}

 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.imbtn-primary {
  background-color: $color-orange;
  font-family: $font-poppins;
  font-size: 14px;
  border:1px solid $color-orange !important;
  &:hover, &:focus{
      border:1px solid $color-orange !important;
      color: $color-orange !important;
      background-color: #fff !important;
  }
}
.imbtn-secondary {
  font-family: $font-poppins;
  font-size: 14px;
  border:1px solid $color-orange;
  background: #fff;
  color: $color-orange;
  &:hover, &:focus{
      background-color: $color-orange !important;
      border:1px solid $color-orange;
      color: $color-white !important;
  }

}
.rupee-symbol{
  font-family: 'Inter', sans-serif;
}
.package-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .nonPurchased{
    color: #fff;
    border-radius: 7px;
    font-size: 18px;
    font-weight: 500;
    //background-color: #FF6700;
    //text-decoration: none;
    padding: 6px 16px;
    & img{
      width: 24px;
    }
  }
}
.password-eye-btn{
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  &.signup-eye-btn{
    position: absolute;
    top: 32px;
    right: 0px;
  }
   
}
.regnow-button.subjectBtn{
  background-color: #f78628;
}
.cartsubjectBtn{
  border-color: #f78628;
  &:hover{
    background-color: #f78628;
    color: #fff;
  }
    & svg{
      fill: #f78628;
    }
}