.login-wrapper {
    min-width: 400px;
    margin: 0 auto;
    width: 75%;
    align-items: center;
}

.login-title {
    color: #000;
    font-family: 'Inter';
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 36px;
    text-align: center;
} 
.login-btn {
    border-radius: 8px;
    background: #FF6700;
    height: 52px;
    color: #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border: 0;
    outline: none;
    margin-top: 26px;
    margin-bottom: 22px;
    width: 100%;
}

.signup-link {
    color: #000;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: block;
    text-align: center;
    & span{
        text-decoration: underline;
        cursor: pointer;
    }

}

.login-label {
    color: #646464;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: block;
}

.login-input-group {
    border-radius: 8px;
    border: 0.899px solid #96A4C2;
    background: #FFF;
    height: 52px; 
    & span {
        border: 0;
        background: transparent;
    }

    & input {
        border: 0;
        border-radius: 8px;
        color: #000;
        font-family: 'Inter';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        &:focus {
            box-shadow: none;
            border: none;
        }
    }
}

.login-check-label {
    color: #000;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.input-error{
    color: red;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    position: relative;
    top: -13px;
}

.Forgot-box {
    display: flex;
    justify-content: space-between;

    p {
        color: #000;
        text-align: right;
        font-family: 'Inter';
        font-size: 14.379px;
        font-style: normal;
        font-weight: 400;
        line-height: 25.163px;
        margin-bottom: 0; 
        text-decoration: underline; 
        cursor: pointer;
        &:hover{
            color: #FF6700; 
        }
    }
}
.input-group-text{
    padding-right: 0 !important;
}