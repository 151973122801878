
@mixin xxl {
    @media (min-width: 1920px) {
      @content;
    }
}
  
@mixin xl {
    @media (min-width: 1440px) and (max-width: 1919px) {
      @content;
    }
}
  
@mixin lg {
    @media (min-width: 1024px) and (max-width: 1439px) {
      @content;
    }
} 
@mixin md {
  @media (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}
@mixin sm {
  @media (min-width: 576px) and (max-width: 767px) {
    @content;
  }
}
@mixin xs {
  @media (min-width: 0px) and (max-width: 575px) {
    @content;
  }
}