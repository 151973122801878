.examTable {
  margin-bottom: 60px;
  .subjectListFilter{
    width: 200px;
    margin-bottom: 20px;
  }
  .header-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    background: #2C93CD;

    &>li {
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 12px;
      flex: 1;
      text-align: center;

      &.examtitle-header-li {
        flex: 2;
      }

      &:first-of-type {
        text-align: center;
      }
    }
  }

  .test_list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #DBE7FF;
    align-items: center;
    
    & .examtitle-li {
      display: flex;
      flex: 2;
    }

    &.disable {
      background: #F6F6F6;
      opacity: 0.6;
      pointer-events: none;
    }

    &>li {
      color: #222;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      flex: 1;
      text-align: center;
      padding: 16px 10px;

      &.Pass {
        color: #4C9732;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &.Fail {
        color: #D52116;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &:first-of-type {
        text-align: center;
      }

      &>img {
        margin-right: 16px;
        width: 20px;
      }

      &.textLink {
        color: #FF6700;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .accord-list {
    border-radius: unset;

    & .accordion-header {
      & .accordion-button {
        color: #222;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background: #F7FCFF;
        border: 1px solid #EDEDED;

        &:focus {
          box-shadow: none;
        }
      }

    }

    & .accordion-body {
      padding: 0;
    }

    & .accordion-button:not(.collapsed) {
      background: #F7FCFF;
      border: 1px solid #EDEDED;
    }
  }
  .topHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>h4 {
      color: #010101;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 16px;
    }
    &>span{
      font-weight: 600;
    }
  }
 

  & table {
    border: 1px solid #EDEDED;

    &>thead {
      &>tr {
        &>th {
          background: #2C93CD;
          color: #FFF;
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 12px;

          &:first-of-type {
            padding-left: 22px;
          }
        }
      }
    }

    &>tbody {
      &>tr {
        &>td {
          color: #222;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          &:first-of-type {
            padding-left: 22px;
          }

          &>img {
            margin-right: 16px;
            width: 20px;
          }

          &.textLink {
            color: #FF6700;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }

}

.DetailsCard-modal {
  .modal-content {
    justify-content: center;
    background: transparent;
    box-shadow: none;
    border: none;

    .DetailsCard {
      margin: 0 auto;
    }
  }
}

.complete-icon {
  & svg {
    width: 24px;
    fill: #4C9732;
  }
}