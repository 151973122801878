@import '../../globals/styles.scss';
@import '../../globals/fonts.scss';
@import '../../globals/breakpoints.scss';

.speciality-carousel {
    width: 100%;
    min-height: 430px;
    background: #EBF3F5;

    display: flex;
    justify-content: center;

    &>.header-image {
        position: absolute;
        height: 435px;
    }

    & .myCourse {
        margin-bottom: 60px;

        & h3 {
            color: #FBFBFB;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 78px;
            /* 216.667% */
        }
    }

    & .newCourse {
        margin-bottom: 60px;

        & h3 {
            color: #FBFBFB;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 78px;
            /* 216.667% */
        }
    }
    & .mySemList{
        margin-bottom: 60px;

        & h3 {
            color: #FBFBFB;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 78px;
            /* 216.667% */
        }
    }

    &>.container {
        &.carouselSlider-box {

            max-width: 1120px;
            padding: 110px 100px 80px;
            margin: 0 auto;
            z-index: 99;

            & .title {
                color: #FBFBFB;
                font-family: $font-poppins;
                font-size: 36px;
                font-style: normal;
                font-weight: 600;
                line-height: 78px;
            }

            &>.desc {
                color: #FFF;
                font-family: $font-poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 20px; 
                &.specdesc{
                    margin-bottom: 36px; 

                }
            }
        }
    }
}

.package-details {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;

    &>.package-details-box {
        max-width: 1120px;
        padding: 0 100px;

        &>.title {
            color: #FF6700;
            text-align: center;
            font-family: $font-poppins;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: 78px;
        }
    }

}

.newCourse-details {
    display: flex;
    justify-content: center;
    background: #F9F4F0;

    & .newCourse {
        margin-bottom: 60px;
        width: 100%;
        max-width: 1120px;
        padding: 40px 100px 40px;
        & h3 {
            color: #FF6700;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 78px;
            /* 216.667% */
        }
    }

}
.specPackage{
    display: flex;
    justify-content: center;
    background: #EBF3F5;
}
 
.Spec-wrapper{
    margin-bottom: 30px;
    &:last-child{
        margin-bottom: 60px;
    }
    & .Spec-name{
        font-size: 22px;
        margin-bottom: 20px;
        &.whitecolor{
            color: #fff;
        } 
    }
}
