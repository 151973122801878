 .list-slider-carousel {
   &>.slick-slider.slick-initialized {
     padding-bottom: 14px;

     &>.slick-list {
      &>.slick-track {
        margin-left: 0;
      }
       & .slick-slide {
         padding: 0 10px;
       }
     }

     &>.slick-dots {
       margin-top: 30px;

       li {
         width: 6px;

         &>button {
           &:before {
             color: #D9D9D9;
             opacity: 1;
             font-size: 10px;
           }
         }

         &.slick-active {
           &>button {
             &:before {
               color: #929292;
             }
           }

         }
       }

     }

     &>.slick-arrow {
       &.slick-prev {
         &.slick-disabled {
           display: none;
         }

         &:before {
           opacity: 0;
         }

         &>img {
           position: relative;
           left: -55px;
           width: 56px;
           height: 56px;
           top: -40px
         }
       }

       &.slick-next {
         &.slick-disabled {
           display: none;
         }

         &:before {
           opacity: 0;
         }

         &>img {
           position: relative;
           right: -19px;
           width: 56px;
           height: 56px;
           top: -40px
         }
       }
     }
   }
 }

 .packageslider-box {
   & .slick-list {
     &>.slick-track {
       display: flex;
       flex-wrap: wrap;

       &>.slick-slide {
         height: inherit;

         &>div {
           height: 100%;
         }
       }
     }
   }
 }