.specialitydetails-wrapper {
  width: 100%;
  min-height: 430px;
  display: flex;
  justify-content: center;

  &>.header-image {
    position: absolute;
    height: 435px;
  }

  &>.container {
    &.specialitydetails-box {
      max-width: 1180px;
      padding: 80px 0 80px;
      margin: 0 auto;
      z-index: 99;
      display: flex;

      & .backbtn {
        max-width: 60px;
        cursor: pointer;
        padding-top: 48px;
      }

      & .specialitydetails-box-detail-conatiner {
        padding: 0 60px 0 30px;
      }

      & .specialitydetails-box-header {
        &>h2 {
          color: #FBFBFB;
          font-size: 38px;
          font-style: normal;
          font-weight: 600; 
        }

        & .desc {
          color: #FFF;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal; 
        }

        & .price {
          color: #FFF;
          font-size: 26px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      & .specialitydetails-box-suboptions {
        list-style: none;
        margin: 10px 0 18px;
        padding: 0;
        margin-bottom: 24px;

        & li {
          color: #FFF;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
          align-items: center;
          margin-bottom: 5px;

          & img {
            margin-right: 10px;
          }
        }
      }

      & .speciality-list-card {
        margin-top: 52px;
        &.without-purchased{
        margin-top: 60px;

        }
        &>.card-title {
          color: #010101;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        & .list-group {
          margin-top: 13px;
          margin-bottom: 13px;

          & .list-group-item {
            display: flex;
            align-items: center;
            padding: 0;
            margin-bottom: 8px;
            border: none;
            color: #222;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            &>img {
              width: 24px;
              height: 24px;
              margin-right: 10px;

            }
          }
        }

      }

      & .specialitydetails-packagecard {
        border-radius: 14px;
        background: #FFF;
        box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.12);
        max-width: 350px;

        &>img {
          width: 100%;
        }

        &-details {
          padding: 26px 26px 36px;

          & h3 {
            color: #222;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          & p {
            color: #3c3c3c;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 20px;
          }

          & .specialitydetails-box-suboptions {
            li {
              color: #181818;
            }

          }
        }
      }
    }
  }
}

.examTable {
  margin-bottom: 60px;
  &>h4 {
    color: #010101;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 16px;
  }

  & table {
    border: 1px solid #EDEDED;

    &>thead {
      &>tr {
        &>th {
          background: #2C93CD;
          color: #FFF;
          font-size: 17px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 12px;

          &:first-of-type {
            padding-left: 22px;
          }
        }
      }
    }

    &>tbody {
      &>tr {
        &>td {
          color: #222;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 16px;

          &:first-of-type {
            padding-left: 22px;
          }
          & > img{
            margin-right: 16px;
            width: 20px;
          }
          &.textLink{
            color: #FF6700; 
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }

}