.FeedBackWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;

    &>h2 {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    &>p {
        font-size: 18px;
        margin-bottom: 40px;
    }

    & .btn-section {
        margin-top: 60px;
       & .imbtn-primary{
            &:disabled {
                background-color: #FF6700; 
                cursor: not-allowed;
            }
        }
        &>button {
            padding: 10px 20px;
            font-size: 18px;
            font-weight: 600;
            margin-right: 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                background-color: #f1f1f1;
            }
        }
    }

    .rateingWrapperBox {
        display: flex;
        justify-content: space-between;
        gap: 35px;

    }

    .rateingWrapper {
        display: flex;
        flex-direction: column;
        text-align: center;
        cursor: pointer;
        &>img {
            width: 90px;
            border: 1px solid gray;
            border-radius: 50%;
            margin-bottom: 10px;
        }
        &>span {
            font-size: 60px;
            display: flex;
            line-height: 1;
            margin-bottom: 10px;
            border: 1px solid #FF6700;
            border-radius: 50%;
            padding: 8px;
            &>img {
                width: 50px;
            }
        } 
        &:hover { 
            label {
                color: #FF6700;
                font-weight: 500;
            } 
            &>img{
                background: #ffece0;
            }
        }
        &.active {
            label {
                color: #FF6700;
                font-weight: 500;
            }
            &>img{
                background: #ffece0;
            } 
        }
     
       
    }


}