@import '../../globals/styles.scss';
@import '../../globals/fonts.scss';
@import '../../globals/breakpoints.scss';

.im-courses {
    .title {
        font-size: 1.8em;
        color: $color-orange;
        font-weight: 600; 
        text-align: center;
        font-family: $font-poppins; 
        padding-top:30px;
     
    }
    .subtitle{
        text-align: center;
        font-style: italic;
        color: #FF6700;
        font-size: 20px;
        padding-bottom: 10px;
    }
    .course-tabs {
        background-color: #f8f4f1;
        .container {
            max-width: 1117px;
            @include xl {
                max-width: 987px;
            }
        }
    }
}