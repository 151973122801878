.quizWrapper {
    padding-top: 26px;
    padding-bottom: 49px;
    background: url('../../assets/QUIZ.png') no-repeat;
    height: inherit;
    background-size: cover;
}

.quizWrapperContainer {
    max-width: 1500px;
    max-height: 100%;
}

.quizHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .noOfQuestion {
        color: #1E1E1E;
        font-family: 'Poppins';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .timeleft-disclimer {
        color: #020202;
        font-family: 'Poppins';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .timeleft-wrapper {
        display: flex;
        align-items: center;

        p {
            margin: 0;
        }
    }
    .My-Timer-Box{
       
        width: 120px;
        height: 46px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: url('../../assets/quiz/time.png') no-repeat;
        background-size: cover;
        font-weight: 600;
    }
    .timeleftTest {
        border-radius: 12px;
        border: 1px solid #fff;
        background: #FF6700;
        width: 120px;
        height: 46px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            color: #fff;
            text-align: center;
            margin-bottom: 0;
            font-family: 'Inter';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

    }
}

.quizAnswerSection {
    border-radius: 8px;
    padding: 70px 40px;
    background: #FFF;
    box-shadow: 0px -1px 15px 0px rgba(0, 0, 0, 0.11);
    margin-top: 26px;
    height: calc(100vh - 280px);
    overflow-y: auto;
    border: 4px solid #FF6700;

    &.FinalScore{
        padding: 50px 30px;
    }
    &.wrong {
        border: 2px solid #fecdcd;
        background: #fff7f7;
    }

    &.correct {
        border: 2px solid #d2faeb;
        background: #f9fbfa;
    }
}

.quizNext {
    border-radius: 4px;
    background: #FF6700;
    width: 184px;
    height: 55px;
    text-transform: uppercase;
    color: #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 0;
    position: relative;

    &>img {
        position: absolute;
        right: 18px;
    }

    &:disabled {
        background: #FF6700;
        color: #FFF;
    }
}

.quizReview {
    border-radius: 4px;
    width: 184px;
    height: 55px;
    text-transform: uppercase;
    color: #000;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 1px solid #FF6700;
    position: relative;
    margin-right: 20px;

    &:disabled {
        color: #000;
        border: 1px solid #FF6700;
    }
}

.btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 34px;
}

.quizQuestionImage {
    margin-top: 10px;
    width: 150px;
}

.quizQuestion {
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.quizQuestionType {
    color: #AEAEAE;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    margin-top: 32px;
    margin-bottom: 50px;
    text-align: center;
}

.quizQuestionHeader {
    text-align: center;
}

 

.quizReviewList {
    height: calc(100vh - 72px);
    overflow-y: scroll;
}
.correct-answer-result{
    margin-top: 24px;
    font-weight: 600;
    &>img{
        width: 200px;
    }
}
.FinalScore.quizAnswerSection.quizReviewListSection {
    & .AnswerOption{
        min-height: 53px;
        margin-right: 12px;
       
    }
    & .MatchAnswer{
        & .MatchAnswerList{
             & > input{
                margin-left: 12px;
                &:disabled{
                    background: #FFF9F4;
                }
             }
            & .correctMatch{
                border-color: green;
            }
            & .wrongMatch{
                border-color: red;
            }
        }
    }

    & .SequenceAnswer{
        & .SequenceAnswerList{
             & > input{
                margin-left: 12px;
                &:disabled{
                    background: #FFF9F4;
                }
             }
            & .correctMatch{
                border-color: green;
            }
            & .wrongMatch{
                border-color: red;
            }
        }
    }
}